import { makeStyles } from 'makeStyles';

const useStyles = makeStyles()(() => ({
  dialogHeaderWrapper: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  dialogContentWrapper: {
    alignSelf: 'center',
  },
}));

export default useStyles;
