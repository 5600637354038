import React from 'react';
import { observer } from 'mobx-react';

import { Route } from 'react-router-dom';

import { FileUploader, SceneViewer } from 'pages';

const Router = () => {
  return (
    <>
      <Route exact={true} path="/">
        <FileUploader />
      </Route>
      <Route exact={true} path="/view">
        <SceneViewer />
      </Route>
    </>
  );
};

export default observer(Router);
