import { makeAutoObservable } from 'mobx';

import { ObjectActions, ObjectRenderModel } from 'models';
import l3dFormatStore from './l3dFormatStore';

class SceneStore {
  constructor() {
    makeAutoObservable(this, undefined, { autoBind: true });
  }

  public scene: ObjectRenderModel | null = null;

  public luminaireActions = new ObjectActions();

  public clear() {
    this.scene = null;
    this.luminaireActions = new ObjectActions();
  }
}

export default new SceneStore();
