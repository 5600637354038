import { makeStyles } from 'makeStyles';

const useStyles = makeStyles()(() => ({
  mainWrapper: {
    height: '100vh',
    display: 'flex',
    flexDirection: 'column',
  },
  mainContent: {
    height: '100%',
    overflow: 'hidden',
    margin: 20,
  },
}));

export default useStyles;
