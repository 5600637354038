import React from 'react';
import { appStore } from 'stores';

import { Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';

import useStyles from './styles';

interface AlertDialogProps {
  title?: string;
  text: string;
  open: boolean;
  onClose: () => void;
}

const AlertDialog: React.FC<AlertDialogProps> = (props) => {
  const { open, text, title, onClose } = props;

  const { classes } = useStyles();

  return (
    <>
      <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
        {title && (
          <DialogTitle sx={{ p: 2 }} className={classes.dialogHeaderWrapper}>
            <ErrorOutlineIcon color="error" fontSize="large" />
            <Box sx={{ ml: 1 }}> {title}</Box>
          </DialogTitle>
        )}
        <DialogContent className={classes.dialogContentWrapper}>
          <DialogContentText style={{ fontSize: '18px', color: 'rgba(0, 0, 0, 0.87)' }}>{text}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose}>Ok</Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default AlertDialog;
