import { createMakeStyles } from 'tss-react';

const theme = {
  backgroundSurfaceColor: 'lightGrey',
  spacing: [0, 2, 3, 5, 8],
};

function useTheme() {
  return theme;
}

export const {
  makeStyles,
  useStyles, //<- To use when you need css or cx but don't have custom classes
} = createMakeStyles({ useTheme });
