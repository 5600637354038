import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import { useHistory } from 'react-router';
import { l3dFormatStore, sceneStore } from 'stores';

import { Button, FormControlLabel, Grid, Switch } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import FilePresentIcon from '@mui/icons-material/FilePresent';

import { Scene } from './Components';

import useStyles from './styles';
import { toJS } from 'mobx';

const SceneViewer: React.FC = () => {
  const { classes } = useStyles();
  const history = useHistory();

  useEffect(() => {
    if (l3dFormatStore.objectsGeometry.length === 0) {
      history.push('/');
    }
  }, []);

  const goBack = () => {
    history.goBack();
  };

  const onJointsLuminaire = (event: React.ChangeEvent<HTMLInputElement>) => {
    sceneStore.luminaireActions.setJointsLuminaire(event.target.checked);
    if (sceneStore.luminaireActions.isJointsLuminaire) sceneStore.scene!.addJointsLuminaire(l3dFormatStore.joints);
    else sceneStore.scene!.removeJointsLuminaire();
  };

  const onElectricalConnectors = (event: React.ChangeEvent<HTMLInputElement>) => {
    sceneStore.luminaireActions.setElectricalConnectors(event.target.checked);
    if (sceneStore.luminaireActions.isElectricalConnectors) sceneStore.scene!.addConnectors(l3dFormatStore.electricalConnectors);
    else sceneStore.scene!.removeConnectors('ElectricalConnector');
  };

  const onPendulumConnectors = (event: React.ChangeEvent<HTMLInputElement>) => {
    sceneStore.luminaireActions.setPendulumConnectors(event.target.checked);
    if (sceneStore.luminaireActions.isPendulumConnectors) sceneStore.scene!.addConnectors(l3dFormatStore.pendulumConnectors);
    else sceneStore.scene!.removeConnectors('PendulumConnector');
  };

  const onLightEmittingObject = (event: React.ChangeEvent<HTMLInputElement>) => {
    sceneStore.luminaireActions.setLightEmittingObject(event.target.checked);
    if (sceneStore.luminaireActions.isLightEmittingObject) sceneStore.scene!.addLightEmittingObjects(l3dFormatStore.lightEmittingObjects);
    else sceneStore.scene!.removeLEOGeometry();
  };

  const onLightEmittingFaces = (event: React.ChangeEvent<HTMLInputElement>) => {
    sceneStore.luminaireActions.setLightEmittingFaces(event.target.checked);
    if (sceneStore.luminaireActions.isLightEmittingFaces)
      sceneStore.scene!.addLightEmittingFaces(l3dFormatStore.lightEmittingFaces, l3dFormatStore.lightEmittingFacesMask);
    else sceneStore.scene!.removeLightEmittingFaces(l3dFormatStore.lightEmittingFaces);
  };

  return (
    <Grid container flexDirection="column">
      <Grid item sx={{ pl: 2 }}>
        <div className={classes.fileName}>
          <Button startIcon={<ArrowBackIcon />} color="primary" variant="contained" onClick={goBack}>
            Back
          </Button>
          <Button sx={{ ml: 2 }} variant="contained" onClick={sceneStore.scene?.fitToObject}>
            Fit to object
          </Button>

          <FilePresentIcon sx={{ ml: 2 }} />
          {l3dFormatStore.fileName}
        </div>
      </Grid>
      <Grid item p={2}>
        <Grid container>
          <Grid item xs={6} md={10}>
            <Scene />
          </Grid>
          <Grid item xs={6} md={2}>
            <div className={classes.actions}>
              <FormControlLabel
                disabled={l3dFormatStore.lightEmittingObjects.length == 0}
                className={classes.action}
                control={<Switch checked={sceneStore.luminaireActions.isLightEmittingObject} onChange={onLightEmittingObject} />}
                label="Light Emitting Object"
              />
              <FormControlLabel
                disabled={l3dFormatStore.joints.length == 0}
                className={classes.action}
                control={<Switch checked={sceneStore.luminaireActions.isJointsLuminaire} onChange={onJointsLuminaire} />}
                label="Joints of the luminaire"
              />
              <FormControlLabel
                disabled={l3dFormatStore.lightEmittingFaces.length == 0}
                className={classes.action}
                control={<Switch checked={sceneStore.luminaireActions.isLightEmittingFaces} onChange={onLightEmittingFaces} />}
                label="Light Emitting faces"
              />
              <FormControlLabel
                disabled={l3dFormatStore.electricalConnectors.length == 0}
                className={classes.action}
                control={<Switch checked={sceneStore.luminaireActions.isElectricalConnectors} onChange={onElectricalConnectors} />}
                label="Electrical Connectors"
              />
              <FormControlLabel
                disabled={l3dFormatStore.pendulumConnectors.length == 0}
                className={classes.action}
                control={<Switch checked={sceneStore.luminaireActions.isPendulumConnectors} onChange={onPendulumConnectors} />}
                label="Pendulum Connectors"
              />
            </div>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default observer(SceneViewer);
