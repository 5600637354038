import { makeAutoObservable } from 'mobx';

class AppStore {
  constructor() {
    makeAutoObservable(this, undefined, { autoBind: true });
  }

  public isOpenAlertDialog = false;

  public textAlertDialog = '';

  public setOpenedAlertDialog(isOpen: boolean) {
    this.isOpenAlertDialog = isOpen;
  }

  public setTextAlertDialog(text: string) {
    this.textAlertDialog = text;
  }
}

export default new AppStore();
