import { IElectricalConnector, IFace, IJoint, ILightEmittingObject, IPendulumConnector } from 'shared/interfaces/app';
import { appStore } from 'stores';
import { makeAutoObservable, runInAction, toJS } from 'mobx';
import { IGeometry, IGeometryFileDefinition, IJointDto, ILightEmittingFace, ILightEmittingObjectDto, IPosition } from 'shared/interfaces/api';

import { ObjectGeometryModel } from 'models';

// eslint-disable-next-line
const { XMLParser, XMLValidator } = require('fast-xml-parser');

class l3dFormatStore {
  constructor() {
    makeAutoObservable(this, undefined, { autoBind: true });
  }

  public isPending = false;

  public prepareObjectsData: any[] = [];

  public joints: IJoint[] = [];

  public lightEmittingObjects: ILightEmittingObject[] = [];

  public lightEmittingFaces: IFace[] = [];

  public electricalConnectors: IElectricalConnector[] = [];

  public pendulumConnectors: IPendulumConnector[] = [];

  public objectsGeometry: ObjectGeometryModel[] = [];

  public geometryDefinitions: IGeometryFileDefinition[] = [];

  public mtlData = '';

  public XMLFile = '';

  public fileName = '';

  public lightEmittingFacesMask: number[] = [];

  public get sceneObjects() {
    this.objectsGeometry.map((item) => {
      const geometryDefinition = this.geometryDefinitions.find((j) => j.id === item.geometryId);
      if (!geometryDefinition) return;
      item.setFilename(geometryDefinition.filename);
      item.setUnit(geometryDefinition?.units);
    });

    this.objectsGeometry.map((item) => {
      const content = this.prepareObjectsData.find((j) => j.file.includes(item.filename, 0)).content;
      item.setTextualObjStructure(content);
    });

    return this.objectsGeometry;
  }

  public setPrepareObjectsData(obj: any) {
    this.prepareObjectsData.push(obj);
  }

  public setGeometryDefinitions(obj: IGeometryFileDefinition) {
    this.geometryDefinitions.push(obj);
  }

  public setJoints(obj: IJoint) {
    this.joints.push(obj);
  }

  public setElectricalConnectors(ec: IElectricalConnector) {
    this.electricalConnectors.push(ec);
  }

  public setPendulumConnectors(pc: IPendulumConnector) {
    this.pendulumConnectors.push(pc);
  }

  public setFileName(name: string) {
    this.fileName = name;
  }

  public setLightEmittingObjects(obj: ILightEmittingObject) {
    this.lightEmittingObjects.push(obj);
  }

  public setLightEmittingFaces(obj: IGeometry) {
    const face: IFace = { rangeAssignment: [], assignment: [], geometryId: '' };

    !obj.LightEmittingFaceAssignments ? this.lightEmittingFacesMask.push(0) : this.lightEmittingFacesMask.push(1);

    if (!obj.LightEmittingFaceAssignments) return;

    face.geometryId = obj.GeometryReference.geometryId;

    if (!!obj.LightEmittingFaceAssignments.RangeAssignment) {
      if (Array.isArray(obj.LightEmittingFaceAssignments.RangeAssignment)) {
        face.rangeAssignment = obj.LightEmittingFaceAssignments.RangeAssignment.map((i) => {
          return {
            max: Number.parseInt(i.faceIndexEnd),
            min: Number.parseInt(i.faceIndexBegin),
          };
        });
      } else {
        face.rangeAssignment.push({
          max: Number.parseInt(obj.LightEmittingFaceAssignments.RangeAssignment.faceIndexEnd),
          min: Number.parseInt(obj.LightEmittingFaceAssignments.RangeAssignment.faceIndexBegin),
        });
      }
    }

    if (!!obj.LightEmittingFaceAssignments.Assignment) {
      if (Array.isArray(obj.LightEmittingFaceAssignments.Assignment)) {
        face.assignment = obj.LightEmittingFaceAssignments.Assignment.map((i) => {
          return {
            faceIndex: Number.parseInt(i.faceIndex),
            groupIndex: Number.parseInt(i.groupIndex),
          };
        });
      } else {
        face.assignment.push({
          faceIndex: Number.parseInt(obj.LightEmittingFaceAssignments.Assignment.faceIndex),
          groupIndex: Number.parseInt(obj.LightEmittingFaceAssignments.Assignment.groupIndex),
        });
      }
    }

    this.lightEmittingFaces.push(face);
  }

  public setMtlData(data: string) {
    this.mtlData = data;
  }

  public setObjectsGeometry(item: IGeometry) {
    const dto = {
      geometryId: item.GeometryReference.geometryId,
      position: item.Position,
      rotation: item.Rotation,
      partName: item.partName,
    };

    this.objectsGeometry.push(new ObjectGeometryModel(dto));
  }

  public async uploadL3DFile(e: any) {
    try {
      this.isPending = true;

      // eslint-disable-next-line
      const zip = require('jszip')();

      let files;
      if (e.target.files) {
        this.setFileName(e.target.files[0].name);
        files = e.target.files;
        for (let file = 0; file < e.target.files!.length; file++) {
          zip.file(files[file].name, files[file]);
        }
      } else {
        this.setFileName(e.dataTransfer.files[0].name);
        files = e.dataTransfer.files;
        for (let file = 0; file < e.dataTransfer.files!.length; file++) {
          zip.file(files[file].name, files[file]);
        }
      }

      const promises: any = [];

      const zipFiles = await zip.loadAsync(files[0]);

      Object.keys(zipFiles.files).forEach(async (file) => {
        if (zip.files[file].name.indexOf('.xml', 0) > -1) {
          const promise = zip.files[file].async('string');
          promises.push(promise);
          this.XMLFile = await promise;
        } else if (zip.files[file].name.indexOf('.mtl', 0) > -1) {
          const promise = zip.files[file].async('string');
          promises.push(promise);
          this.setMtlData(await promise);
        } else if (zip.files[file].name.indexOf('.obj', 0) > -1) {
          const promise = zip.files[file].async('string');
          promises.push(promise);
          this.setPrepareObjectsData({
            file: file,
            content: await promise,
          });
        }
      });

      await Promise.all(promises);
      this.XMLParse(this.XMLFile);
    } catch (e) {
      appStore.setOpenedAlertDialog(true);
      appStore.setTextAlertDialog('Format is not l3d');
    } finally {
      runInAction(() => {
        this.isPending = false;
        e.target.value = '';
      });
    }
  }

  public XMLParse(XML: string) {
    try {
      const validXML = XMLValidator.validate(XML);
      if (validXML.hasOwnProperty('err')) throw new Error();

      const options = {
        ignoreAttributes: false,
        attributeNamePrefix: '',
        allowBooleanAttributes: true,
      };

      const parser = new XMLParser(options);
      const prepareObj = parser.parse(XML);

      const geometryFileDefinition = prepareObj.Luminaire.GeometryDefinitions.GeometryFileDefinition;
      Array.isArray(geometryFileDefinition)
        ? geometryFileDefinition.map((i: IGeometryFileDefinition) => this.setGeometryDefinitions(i))
        : this.setGeometryDefinitions(geometryFileDefinition);

      if (this.geometryDefinitions.length !== this.prepareObjectsData.length) throw new Error();

      this.getProp(prepareObj);

      // XML data parsing with saving information about parentNode and childNode
      const parserDOM = new DOMParser();
      const xmlDoc = parserDOM.parseFromString(XML, 'text/xml');

      const geomRefs = xmlDoc.getElementsByTagName('GeometryReference');
      const joinRefs = xmlDoc.getElementsByTagName('Joint');
      const leoRectRefs = xmlDoc.getElementsByTagName('Rectangle');
      const leoCircleRefs = xmlDoc.getElementsByTagName('Circle');

      const nodesArray = Array.from(geomRefs);
      const jointsNodesArray = Array.from(joinRefs);
      const leoRectNodesArray = Array.from(leoRectRefs);
      const leoCircleNodesArray = Array.from(leoCircleRefs);

      this.objectsGeometry.forEach((i, idx) => {
        nodesArray.forEach((mask: any) => {
          if (this.isEqualParentParts(mask, i)) {
            const result = this.calculateRelativeCoords(mask);
            i.position = result.position;
            i.rotation = result.rotation;
          }
        });
      });

      this.joints.forEach((i, idx) => {
        jointsNodesArray.forEach((mask: any) => {
          if (this.isEqualParts(mask, i)) {
            const result = this.calculateRelativeCoords(mask);
            i.position = result.position;
          }
        });
      });

      this.lightEmittingObjects.forEach((i, idx) => {
        if (i.Circle) {
          leoCircleNodesArray.forEach((mask: any) => {
            if (this.isEqualParentParts(mask, i)) {
              const result = this.calculateRelativeCoords(mask);
              i.Position = result.position;
            }
          });
        } else {
          leoRectNodesArray.forEach((mask: any) => {
            if (this.isEqualParentParts(mask, i)) {
              const result = this.calculateRelativeCoords(mask);
              i.Position = result.position;
            }
          });
        }
      });
    } catch (e) {
      appStore.setOpenedAlertDialog(true);
      appStore.setTextAlertDialog('Format is not l3d');
    }
  }

  private isEqualParentParts(part1: any, part2: any) {
    const partName1 = part1.parentNode.attributes.getNamedItem('partName').value;
    const partName2 = part2.partName;

    return partName1 === partName2;
  }

  private isEqualParts(part1: any, part2: any) {
    const partName1 = part1.attributes.getNamedItem('partName').value;
    const partName2 = part2.partName;

    return partName1 === partName2;
  }

  private getProp(obj: any) {
    for (const prop in obj) {
      switch (prop) {
        case 'Geometry':
          this.setObjectsGeometry(obj[prop]);
          this.setLightEmittingFaces(obj[prop]);

          if (obj[prop].LightEmittingObjects) {
            const geometryId = obj[prop].GeometryReference.geometryId;

            Array.isArray(obj[prop].LightEmittingObjects.LightEmittingObject)
              ? obj[prop].LightEmittingObjects.LightEmittingObject.map((i: ILightEmittingObjectDto) =>
                  this.setLightEmittingObjects({ ...i, geometryId })
                )
              : this.setLightEmittingObjects({ ...obj[prop].LightEmittingObjects.LightEmittingObject, geometryId });
          }
          this.getProp(obj[prop]);
          continue;

        case 'ElectricalConnector':
          Array.isArray(obj[prop])
            ? obj[prop].map((i: IPosition) => this.setElectricalConnectors({ type: prop, position: i }))
            : this.setElectricalConnectors({ type: prop, position: obj[prop] });
          this.getProp(obj[prop]);

          continue;

        case 'PendulumConnector':
          Array.isArray(obj[prop])
            ? obj[prop].map((i: IPosition) => this.setPendulumConnectors({ type: prop, position: i }))
            : this.setPendulumConnectors({ type: prop, position: obj[prop] });
          this.getProp(obj[prop]);

          continue;

        case 'Joint':
          Array.isArray(obj[prop])
            ? obj[prop].map((i: IJointDto) =>
                this.setJoints({ geometryId: i.Geometries.Geometry.GeometryReference.geometryId, position: i.Position })
              )
            : this.setJoints({ geometryId: obj[prop].Geometries.Geometry.GeometryReference.geometryId, position: obj[prop].Position });
          this.getProp(obj[prop]);

          continue;

        default:
          !!(typeof obj[prop] === 'object') && this.getProp(obj[prop]);
      }
    }
  }

  /**
   * Convert absolute coord to relative
   * @param obj
   * @private
   */
  private calculateRelativeCoords(obj: any) {
    let currentObj = obj.parentNode;

    const rootNode = 'Structure';
    const position: IPosition = { x: 0, y: 0, z: 0 };
    const rotation: IPosition = { x: 0, y: 0, z: 0 };

    do {
      const nodesArray = Array.from(currentObj.children);

      // Find Position & Rotation nodes
      const innerNodeNames = nodesArray!.map((item: any) => item.localName);
      const positionIndex = innerNodeNames.indexOf('Position');
      const rotationIndex = innerNodeNames.indexOf('Rotation');

      if (positionIndex >= 0) {
        const innerPosition = currentObj.children[positionIndex];
        position.x += parseFloat(innerPosition.getAttribute('x'));
        position.y += parseFloat(innerPosition.getAttribute('y'));
        position.z += parseFloat(innerPosition.getAttribute('z'));
      }

      if (rotationIndex >= 0) {
        const innerRotation = currentObj.children[rotationIndex];
        rotation.x += parseFloat(innerRotation.getAttribute('x'));
        rotation.y += parseFloat(innerRotation.getAttribute('y'));
        rotation.z += parseFloat(innerRotation.getAttribute('z'));
      }

      currentObj = currentObj.parentNode;
    } while (currentObj.localName !== rootNode);

    return { position, rotation };
  }

  public clear() {
    this.lightEmittingFacesMask = [];
    this.prepareObjectsData = [];
    this.joints = [];
    this.lightEmittingObjects = [];
    this.lightEmittingFaces = [];
    this.pendulumConnectors = [];
    this.electricalConnectors = [];
    this.objectsGeometry = [];
    this.geometryDefinitions = [];
    this.mtlData = '';
    this.XMLFile = '';
  }
}

export default new l3dFormatStore();
