import { makeAutoObservable } from 'mobx';

class ObjectActions {
  public constructor() {
    makeAutoObservable(this, undefined, { autoBind: true });
  }

  public isLightEmittingObject = false;

  public isJointsLuminaire = false;

  public isLightEmittingFaces = false;

  public isElectricalConnectors = false;

  public isPendulumConnectors = false;

  public setLightEmittingObject(checked: boolean) {
    this.isLightEmittingObject = checked;
  }

  public setJointsLuminaire(checked: boolean) {
    this.isJointsLuminaire = checked;
  }

  public setLightEmittingFaces(checked: boolean) {
    this.isLightEmittingFaces = checked;
  }

  public setElectricalConnectors(checked: boolean) {
    this.isElectricalConnectors = checked;
  }

  public setPendulumConnectors(checked: boolean) {
    this.isPendulumConnectors = checked;
  }
}

export default ObjectActions;
