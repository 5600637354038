import React, { useEffect, useRef } from 'react';

import { observer } from 'mobx-react';
import { l3dFormatStore, sceneStore } from 'stores';
import { ObjectRenderModel } from 'models';

import useStyles from './styles';

const Scene: React.FC = () => {
  const sceneRef = useRef<HTMLDivElement | null>(null);

  const { classes } = useStyles();

  useEffect(() => {
    const width = sceneRef.current!.clientWidth;
    const height = sceneRef.current!.clientHeight;

    if (!sceneStore.scene) {
      const dto = {
        sceneSizes: { width, height },
        canvasContainer: sceneRef.current!,
      };

      sceneStore.scene = new ObjectRenderModel(dto);

      sceneStore.scene.renderObjects(l3dFormatStore.sceneObjects, l3dFormatStore.mtlData);
    }

    return () => {
      sceneStore.clear();
    };
  }, []);

  return <div className={classes.sceneWrapper} ref={sceneRef} />;
};

export default observer(Scene);
