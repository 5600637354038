import { makeStyles } from 'makeStyles';

const useStyles = makeStyles()((theme) => ({
  sceneWrapper: {
    width: '100%',
    height: '850px',
    display: 'block',
  },
}));

export default useStyles;
