import { IElectricalConnector } from './../shared/interfaces/app/ISceneActions';
import { makeAutoObservable } from 'mobx';
import * as THREE from 'three';
import { Box3, BufferGeometry, Group, Mesh, PerspectiveCamera } from 'three';
import { OBJLoader } from 'three/examples/jsm/loaders/OBJLoader';
import { MTLLoader } from 'three/examples/jsm/loaders/MTLLoader';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls';

import { ObjectGeometryModel } from 'models';
import { IAssignment, IFace, IJoint, IRangeAssignment, ILightEmittingObject, IPendulumConnector } from 'shared/interfaces/app';

interface IObjectRenderModelDto {
  sceneSizes: {
    width: number;
    height: number;
  };
  canvasContainer: HTMLDivElement;
}
class ObjectRenderModel {
  public constructor(dto: IObjectRenderModelDto) {
    makeAutoObservable(this, undefined, { autoBind: true });

    this.sceneSizes = dto.sceneSizes;

    this.initRenderer(dto.canvasContainer);
    this.scene.background = new THREE.Color('#202020');
    this.camera = new THREE.PerspectiveCamera(75, this.sceneSizes.width / this.sceneSizes.height, 0.01, 1000);
    this.controls = new OrbitControls(this.camera, this.renderer.domElement);

    this.display();
  }

  public canvasContainer: HTMLDivElement | null = null;

  public sceneSizes: { width: number; height: number } = { width: 0, height: 0 };

  public renderer = new THREE.WebGLRenderer({ antialias: true });

  public scene = new THREE.Scene();

  public camera: PerspectiveCamera;

  public controls: OrbitControls;

  public sceneLightMax = new THREE.PointLight(0xffffff, 1, 1000);

  public sceneLightMin = new THREE.PointLight(0xffffff, 1, 1000);

  public hemisphereLight = new THREE.HemisphereLight(0x333333, 0xaaaaaa, 1);

  public material = new THREE.MeshPhongMaterial({
    color: 0xffffff,
    wireframe: false,
    vertexColors: true,
  });

  public groupsBBox: Box3[] = [];

  public boundingBox: Box3 | null = null;

  public jointSphereRadius = 0;

  public defaultColor: number = 0.6;

  public objectsLightEmittingFaces: any[] = [];

  public fitToObject = () => {
    if (this.groupsBBox.length === 0) return;

    this.boundingBox = this.groupsBBox.reduce((prev, cur) => prev.union(cur));

    const middle = new THREE.Vector3();
    const size = new THREE.Vector3();
    this.boundingBox.getSize(size);
    this.boundingBox.getCenter(middle);

    const fov = this.camera.fov * (Math.PI / 180);
    const fovh = 2 * Math.atan(Math.tan(fov / 2) * this.camera.aspect);

    const dx = size.z / 2 + Math.abs(size.z / 2 / Math.tan(fovh / 2));
    const dy = size.z / 2 + Math.abs(size.y / 2 / Math.tan(fov / 2));
    const dz = size.z / 2 + Math.abs(size.x / 2 / Math.tan(fov / 2));
    const distance = Math.max(dx, dy, dz);

    this.camera.position.set(distance / 2, distance, -distance);

    const min = this.boundingBox.min.z;
    const cameraToFarEdge = min < 0 ? -min + distance : distance - min;

    this.camera.far = cameraToFarEdge * 3;
    this.camera.updateProjectionMatrix();

    this.controls.target = middle;
    this.controls.maxDistance = cameraToFarEdge * 2;

    this.camera.lookAt(middle);
  };

  public renderObjects(sceneObjects: ObjectGeometryModel[], mtlData?: string) {
    if (sceneObjects.length === 0) return;

    const mtlLoader = new MTLLoader();
    const objLoader = new OBJLoader();

    if (mtlData) {
      const materials = mtlLoader.parse(mtlData, '');
      objLoader.setMaterials(materials);
    }

    const groups: Group[] = [];

    const textualObjStructure = sceneObjects[0].textualObjStructure;
    const regexp = /f\s[1-9]+.+?(?=\n|\r\n)/;

    let min: number | null = null;
    let max: number | null = null;
    sceneObjects.forEach((obj: ObjectGeometryModel) => {
      const object = objLoader.parse(obj.textualObjStructure);

      // Math min/max object size
      const bbox = new THREE.Box3().setFromObject(object);
      const dX = Math.abs(bbox.max.x - bbox.min.x);
      const dY = Math.abs(bbox.max.y - bbox.min.y);
      const dZ = Math.abs(bbox.max.z - bbox.min.z);
      const objectMin = Math.min(dX, dY, dZ);
      const objectMax = Math.max(dX, dY, dZ);
      groups.push(object);

      if (!min || objectMin < min) min = objectMin;
      if (!max || objectMax > max) max = objectMax;
    });

    // Dynamic near/far for camera
    this.camera.near = (min ?? 10000) / 10;
    this.camera.far = (max ?? 0.001) * 10;

    groups.map((group, groupIndex) => {
      sceneObjects.forEach((obj, ObjIndex) => {
        if (groupIndex === ObjIndex) {
          group.position.x = obj.position.x;
          group.position.y = obj.position.y;
          group.position.z = obj.position.z;

          group.rotation.x = THREE.MathUtils.degToRad(obj.rotation.x);
          group.rotation.y = THREE.MathUtils.degToRad(obj.rotation.y);
          group.rotation.z = THREE.MathUtils.degToRad(obj.rotation.z);

          group.userData.geometryId = obj.geometryId;
          group.userData.unit = obj.unit;
          group.userData.faceSize = textualObjStructure.match(regexp)![0].split(' ').length - 1;
        }
      });

      (group.children as Mesh[]).forEach((mesh) => {
        // add default material
        if (!mtlData) mesh.material = this.material;

        const material = mesh.material as THREE.MeshPhongMaterial;
        material.vertexColors = true;

        const count = mesh.geometry.attributes.position.count;
        const colorAttr = new THREE.Float32BufferAttribute(new Float32Array(count * 3).fill(this.defaultColor), 3);
        mesh.geometry.setAttribute('color', colorAttr);
      });

      // set a bBox of object
      this.groupsBBox.push(new THREE.Box3().setFromObject(group));

      group.name = 'LuminaireObject';

      this.scene.add(group);
    });

    this.fitToObject();
    this.initSceneLight();

    //helper
    /* const axesHelper = new THREE.AxesHelper(10);
    this.scene.add(axesHelper); */
  }

  public removeLEOGeometry() {
    const removedObjects = this.scene.children.filter((i) => i.getObjectByName('LEObject'));

    removedObjects.forEach((obj) => {
      this.clearThree(obj);
      this.scene.remove(obj);
    });
  }

  public removeJointsLuminaire() {
    const removedObjects = this.scene.children.filter((i) => i.getObjectByName('JointsLuminaire'));

    removedObjects.forEach((obj) => {
      this.clearThree(obj);
      this.scene.remove(obj);
    });
  }

  public removeConnectors(type: string) {
    const removedObjects = this.scene.children.filter((i) => i.getObjectByName(type));

    removedObjects.forEach((obj) => {
      this.clearThree(obj);
      this.scene.remove(obj);
    });
  }

  public removeLightEmittingFaces(faces: IFace[]) {
    const color = new THREE.Color(this.defaultColor, this.defaultColor, this.defaultColor);
    this.changeColor(faces, color);
  }

  private clearThree(obj: any) {
    while (obj.children.length > 0) {
      this.clearThree(obj.children[0]);
      obj.remove(obj.children[0]);
    }
    if (obj.geometry) obj.geometry.dispose();

    if (obj.material) {
      Object.keys(obj.material).forEach((prop) => {
        if (!obj.material[prop]) return;
        if (obj.material[prop] !== null && typeof obj.material[prop].dispose === 'function') obj.material[prop].dispose();
      });
      obj.material.dispose();
    }
  }

  public addLightEmittingObjects(objects: ILightEmittingObject[]) {
    objects.map((obj) => {
      const object = this.scene.children.find((i) => i.userData.geometryId === obj.geometryId);

      const k = object!.userData.unit === 'mm' ? 1000 : 1;

      if (obj.Rectangle) {
        const depth = (Math.min(obj.Rectangle.sizeX, obj.Rectangle.sizeY) * k) / 20;
        const boxGeometry = new THREE.BoxGeometry(obj.Rectangle.sizeX * k, obj.Rectangle.sizeY * k, depth);

        this.createLEOGeometry(boxGeometry, obj);
      } else {
        const radius = (obj.Circle!.diameter * k) / 2;
        const depth = radius / 20;
        const cylinderGeometry = new THREE.CylinderGeometry(radius, radius, depth, 64);
        cylinderGeometry.rotateX(-Math.PI * 0.5); // rotate 90 degrees clockwise around x-axis

        this.createLEOGeometry(cylinderGeometry, obj);
      }
    });
  }

  public addLightEmittingFaces(faces: IFace[], mask: number[]) {
    const luminaireObject = this.scene.children.filter((i) => i.getObjectByName('LuminaireObject'));

    luminaireObject.forEach((i, idx) => {
      mask.forEach((mask, maskIndex) => {
        if (idx === maskIndex && mask === 1) this.objectsLightEmittingFaces.push(i);
      });
    });

    const color = new THREE.Color(1, 1, 0.4);
    this.changeColor(faces, color);
  }

  public addJointsLuminaire(joints: IJoint[]) {
    joints.map((joint) => {
      const geometry = new THREE.SphereGeometry(this.jointSphereRadius, 32, 24);
      const material = new THREE.MeshBasicMaterial({ color: 0x008000, transparent: true, opacity: 0.5, depthTest: false });

      const sphere = new THREE.Mesh(geometry, material);

      const object = this.scene.children.find((i) => i.userData.geometryId === joint.geometryId);

      const k = object!.userData.unit === 'mm' ? 1000 : 1;
      sphere.position.x = joint.position.x * k;
      sphere.position.y = joint.position.y * k;
      sphere.position.z = joint.position.z * k;

      sphere.name = 'JointsLuminaire';

      this.scene.add(sphere);
    });
  }

  public addConnectors(connectors: IPendulumConnector[] | IElectricalConnector[]) {
    connectors.map((con) => {
      const geometry = new THREE.SphereGeometry(this.jointSphereRadius, 32, 24);
      const material = new THREE.MeshBasicMaterial({
        color: con.type === 'ElectricalConnector' ? 0xff0000 : 0x000000,
        transparent: true,
        opacity: 0.5,
        depthTest: false,
      });

      const sphere = new THREE.Mesh(geometry, material);

      sphere.position.y = con.position.y;
      sphere.position.z = con.position.z;
      sphere.position.x = con.position.x;

      sphere.name = con.type;

      this.scene.add(sphere);
    });
  }

  // --- Private

  private changeColor(faces: IFace[], color: THREE.Color) {
    this.objectsLightEmittingFaces.forEach((item, itemIndex) => {
      faces.forEach((el: IFace, elIndex) => {
        if (elIndex === itemIndex) {
          if (!!el.assignment) {
            el.assignment.forEach((i: IAssignment) => {
              const mesh = i.groupIndex ? item.children[i.groupIndex] : item.children[0];
              const faceSize = item.userData.faceSize;
              const colorAttr = mesh.geometry.attributes.color.array;
              this.colorFace(colorAttr, color, faceSize, i.faceIndex, i.faceIndex);
              mesh.geometry.attributes.color.needsUpdate = true;
            });
          }

          if (!!el.rangeAssignment) {
            el.rangeAssignment.forEach((i: IRangeAssignment) => {
              const mesh = item.children[0] as THREE.Mesh;
              const faceSize = item.userData.faceSize;
              const colorAttr = mesh.geometry.attributes.color.array;
              this.colorFace(colorAttr, color, faceSize, i.min, i.max);
              mesh.geometry.attributes.color.needsUpdate = true;
            });
          }
        }
      });
    });
  }

  private colorFace(colorArray: any, color: THREE.Color, faceSize: number, startIndex: number, endIndex: number) {
    startIndex = startIndex * (faceSize - 2);
    endIndex = endIndex * (faceSize - 2) + (faceSize - 3);

    for (let index = startIndex; index <= endIndex; index++) {
      for (let vertexIndex = 0; vertexIndex < 3; vertexIndex++) {
        const i = index * 9 + vertexIndex * 3;
        colorArray[i] = color.r;
        colorArray[i + 1] = color.g;
        colorArray[i + 2] = color.b;
      }
    }
  }

  private createLEOGeometry(geometry: BufferGeometry, params: ILightEmittingObject) {
    const material = new THREE.MeshBasicMaterial({
      color: 0xffff00,
      transparent: false,
      opacity: 0.5,
    });
    const LEObject = new THREE.Mesh(geometry, material);

    const offset = params.Rectangle ? Math.min(params.Rectangle!.sizeX, params.Rectangle!.sizeY) / 1000 : params.Circle!.diameter / 1000;

    const object = this.scene.children.find((i) => i.userData.geometryId === params.geometryId);
    const k = object!.userData.unit === 'mm' ? 1000 : 1;

    LEObject.position.x = params.Position.x * k - offset;
    LEObject.position.y = params.Position.y * k - offset;
    LEObject.position.z = params.Position.z * k - offset;

    LEObject.rotation.x = THREE.MathUtils.degToRad(params.Rotation.x);
    LEObject.rotation.y = THREE.MathUtils.degToRad(params.Rotation.y);
    LEObject.rotation.z = THREE.MathUtils.degToRad(params.Rotation.z);

    let size;
    if (params.Rectangle) size = -(Math.min(params.Rectangle.sizeX, params.Rectangle.sizeY) * k) / 20;
    else size = -(params.Circle!.diameter * k) / 20;

    const normalDir = new THREE.Vector3(0, 0, -1); //FIXME: hardcode position normal vector
    const xDir = new THREE.Vector3(1, 0, 0);
    const yDir = new THREE.Vector3(0, 1, 0);
    const origin = new THREE.Vector3(size, size, size);

    const yLength = (params.Rectangle ? params.Rectangle.sizeY / 2.5 : params.Circle!.diameter / 2.1) * k;
    const xLength = (params.Rectangle ? params.Rectangle.sizeX / 2.5 : params.Circle!.diameter / 2.1) * k;
    const normalLength = Math.max(yLength, xLength);

    const normalHelper = new THREE.ArrowHelper(normalDir, origin, normalLength, 0x0000ff, normalLength * 0.1, normalLength * 0.04);
    const yHelper = new THREE.ArrowHelper(yDir, origin, yLength, 0x00ff00, normalLength * 0.1, normalLength * 0.04);
    const xHelper = new THREE.ArrowHelper(xDir, origin, xLength, 0xff0000, normalLength * 0.1, normalLength * 0.04);

    yHelper.children.map((mesh: any) => {
      mesh.material.transparent = false;
      mesh.material.opacity = 1;
      mesh.material.depthTest = true;
    });
    normalHelper.children.map((mesh: any) => {
      mesh.material.transparent = false;
      mesh.material.opacity = 1;
      mesh.material.depthTest = true;
    });
    xHelper.children.map((mesh: any) => {
      mesh.material.transparent = false;
      mesh.material.opacity = 1;
      mesh.material.depthTest = true;
    });

    LEObject.add(normalHelper);
    LEObject.add(xHelper);
    LEObject.add(yHelper);

    LEObject.name = 'LEObject';

    this.scene.add(LEObject);
  }

  private initRenderer(canvasContainer: HTMLDivElement) {
    this.renderer.setSize(this.sceneSizes.width, this.sceneSizes.height);
    canvasContainer.appendChild(this.renderer.domElement);
  }

  private initSceneLight() {
    if (!this.boundingBox) return;

    const dX = this.boundingBox.max.x - this.boundingBox.min.x;
    const dY = this.boundingBox.max.y - this.boundingBox.min.y;
    const dZ = this.boundingBox.max.z - this.boundingBox.min.z;

    this.jointSphereRadius = Math.min(dX, dY, dZ) / 5;

    const lightOffset = Math.max(dX, dY, dZ);

    const posMaxX = this.boundingBox.max.x + lightOffset * 0.2;
    const posMaxY = this.boundingBox.max.y + lightOffset * 0.2;
    const posMaxZ = this.boundingBox.max.z + lightOffset * 0.2;

    const posMinX = this.boundingBox.min.x - lightOffset * 0.2;
    const posMinY = this.boundingBox.min.y - lightOffset * 0.2;
    const posMinZ = this.boundingBox.min.z - lightOffset * 0.2;

    this.sceneLightMax.position.set(posMaxX, posMaxY, posMaxZ);
    this.sceneLightMin.position.set(posMinX, posMinY, posMinZ);

    this.scene.add(this.sceneLightMax);
    this.scene.add(this.sceneLightMin);
    this.scene.add(this.hemisphereLight);

    // helper
    /* const sphereSize = 0.5;
    const pointLightHelperMax = new THREE.PointLightHelper(this.sceneLightMax, sphereSize);
    const pointLightHelperMin = new THREE.PointLightHelper(this.sceneLightMin, sphereSize);
    this.scene.add(pointLightHelperMax, pointLightHelperMin); */
  }

  private renderScene() {
    this.renderer.render(this.scene, this.camera);
  }

  private display = () => {
    this.renderScene();
    window.requestAnimationFrame(this.display);
  };
}

export default ObjectRenderModel;
