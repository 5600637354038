import { makeStyles } from 'makeStyles';

const useStyles = makeStyles()((theme) => ({
  fileName: {
    display: 'flex',
    alignItems: 'center',
  },
  actions: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
  },
  action: {
    width: '80%',
    margin: 10,
  },
}));

export default useStyles;
