import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';

import { observer } from 'mobx-react';
import { toJS } from 'mobx';

import { l3dFormatStore, sceneStore } from 'stores';

import CloudUploadIcon from '@mui/icons-material/CloudUpload';

import useStyles from './styles';

const FileUploader: React.FC = () => {
  const history = useHistory();

  const [isDragAreaHighlighted, setDragAreaHighlighted] = useState(false);

  const { classes } = useStyles({ areaHighlighted: isDragAreaHighlighted });

  const onChangeFile = async (e: React.ChangeEvent<HTMLInputElement>) => {
    l3dFormatStore.clear();
    await l3dFormatStore.uploadL3DFile(e);
    if (l3dFormatStore.objectsGeometry.length > 0) history.push('/view');
  };

  const handleDrop = async (e: React.DragEvent<HTMLLabelElement>) => {
    e.preventDefault();
    l3dFormatStore.clear();
    await l3dFormatStore.uploadL3DFile(e);
    if (l3dFormatStore.objectsGeometry.length > 0) history.push('/view');
  };

  const handleDragOver = (e: React.DragEvent<HTMLLabelElement>) => {
    highlightDragArea();
    e.stopPropagation();
    e.preventDefault();
  };

  const highlightDragArea = () => {
    setDragAreaHighlighted(true);
  };

  const unHighlightDragArea = () => {
    setDragAreaHighlighted(false);
  };

  return (
    <div className={classes.uploaderWrapper}>
      <div className={classes.uploader}>
        <div className={classes.header}>Upload File</div>
        {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions,jsx-a11y/mouse-events-have-key-events */}
        <label
          className={classes.dragArea}
          onMouseOver={highlightDragArea}
          onMouseLeave={unHighlightDragArea}
          onDrop={(e) => handleDrop(e)}
          onDragOver={(e) => handleDragOver(e)}
          onDragEnter={highlightDragArea}
          onDragLeave={unHighlightDragArea}>
          <CloudUploadIcon className={classes.icon} fontSize="large" />
          <span className={classes.dragAreaText}>
            Drag & Drop
            <span>
              or <span style={{ color: '#d87173' }}>browse</span>
            </span>
          </span>
          <span className={classes.dragAreaType}>Supported: .l3d</span>
          <input id="files" type="file" name="file" accept=".l3d" hidden onChange={onChangeFile} />
        </label>
      </div>
    </div>
  );
};

export default observer(FileUploader);
