import React from 'react';
import { BrowserRouter, Switch } from 'react-router-dom';

import { CssBaseline } from '@mui/material';

import Router from 'routes/Router';
import { Layout } from 'components';

import './App.css';

const App: React.FC = () => {
  return (
    <div className="App">
      <CssBaseline />
      <Layout>
        <BrowserRouter>
          <Switch>
            <Router />
          </Switch>
        </BrowserRouter>
      </Layout>
    </div>
  );
};

export default App;
