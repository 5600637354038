import { makeStyles } from 'makeStyles';

const useStyles = makeStyles<{ areaHighlighted: boolean }>()((theme, { areaHighlighted }) => ({
  uploaderWrapper: {
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },

  uploader: {
    borderRadius: '20px',
    backgroundColor: '#ffffff',
    width: '50%',
    boxShadow: '0px 5px 5px -3px rgb(0 0 0 / 20%), 0px 8px 10px 1px rgb(0 0 0 / 14%), 0px 3px 14px 2px rgb(0 0 0 / 12%)',
  },

  header: {
    fontSize: '26px',
    margin: '20px 80px 20px 80px',
  },

  dragArea: {
    backgroundColor: '#ffffff',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    border: '2.5px dashed',
    borderRadius: '15px',
    borderColor: areaHighlighted ? '#262e3e' : '#c4bebe',
    margin: '0 65px 65px 65px',
    height: '50vh',
    cursor: 'pointer',
  },

  dragAreaText: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    fontSize: '24px',
  },

  dragAreaType: {
    fontSize: '14px',
    marginTop: '10px',
    textAlign: 'center',
    color: '#242c3b',
    opacity: '0.8',
  },

  icon: {
    color: '#d87173',
  },
}));

export default useStyles;
