import { makeAutoObservable } from 'mobx';
import { IObjectGeometry, IPosition } from './../shared/interfaces/api';

class ObjectGeometryModel {
  public constructor(dto?: IObjectGeometry) {
    makeAutoObservable(this, undefined, { autoBind: true });

    if (!dto) return;

    this.geometryId = dto.geometryId;
    this.partName = dto.partName;
    this.position = dto.position;
    this.rotation = dto.rotation;
  }

  public geometryId = '';

  public partName = '';

  public position: IPosition = { x: 0, y: 0, z: 0 };

  public rotation: IPosition = { x: 0, y: 0, z: 0 };

  public unit = '';

  public filename = '';

  public textualObjStructure = ``;

  public setFilename(filename: string) {
    this.filename = filename;
  }

  public setUnit(unit: string) {
    this.unit = unit;
  }

  public setTextualObjStructure(structure: string) {
    this.textualObjStructure = structure;
  }
}

export default ObjectGeometryModel;
