import React from 'react';
import { observer } from 'mobx-react';

import { appStore } from 'stores';

import { AlertDialog } from 'components';

import useStyles from './styles';

interface LayoutProps {
  children: JSX.Element;
}

const Layout: React.FC<LayoutProps> = (props) => {
  const { children } = props;
  const { classes } = useStyles();

  return (
    <div className={classes.mainWrapper}>
      <div className={classes.mainContent}>{children}</div>
      <AlertDialog
        open={appStore.isOpenAlertDialog}
        text={appStore.textAlertDialog}
        title="Error"
        onClose={() => appStore.setOpenedAlertDialog(false)}
      />
    </div>
  );
};

export default observer(Layout);
